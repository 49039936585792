
import React from 'react';
import Logo from '../../assets/logo.png';
import  './Logo.css';

const logo = (props) => (
    <div className={"Logo"}>
        
        <img src={Logo} alt="Logo" />
        </div>
);

export default logo;