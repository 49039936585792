import Electricity from './Electricity'
import Heat from './Heat'
import Commute from './Commute'
import "./Calculate.css"

import React, { useState, useEffect } from 'react';

export default function Display(props) {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);
  const[electricity, setElectricity] = useState(0)
  const[heat, setHeat] = useState(0)
  const[commute, setCommute] = useState(0)

  
  useEffect(() => {

    setElectricity(parseInt(localStorage.getItem("Electricity")))
    setHeat(parseInt(localStorage.getItem("Heat")))
    setCommute(parseInt(localStorage.getItem("Commute")))
    console.log(electricity,heat,commute)

  }, [count]);
   
  let getTotal= () => {
    let total = 0
    if(!Number.isNaN(electricity)){
      total += electricity
    }
    if(!Number.isNaN(heat)){
      total += heat
    }
    if(!Number.isNaN(commute)){
      total += commute
    }
    return total
  }
  return (
    <div className = {"Stats-Container"}>
      <div className= {"Carbon-Data"}>
        <ul>
            <li>Electricity: {electricity || 0} MT</li>
            <li>Heat: {heat || 0} MT</li>
            <li>Commute: {commute || 0} MT</li>
        </ul>

      </div>
    
       
      {(electricity||heat||commute)? <h1>Your Total: {getTotal()} MT</h1>: <h1>Your Total: 0</h1>}
    

    
      <button onClick={() => setCount((parseInt(localStorage.getItem("Electricity"))|| 0)+(parseInt(localStorage.getItem("Heat")) ||0)+(parseInt(localStorage.getItem("Commute"))|| 0))}>
        Update
      </button>
    </div>
  );
}